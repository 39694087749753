import { default as articlesh9E8fquLDRMeta } from "/opt/build/repo/frontend/pages/articles.vue?macro=true";
import { default as indexdATqU9DngJMeta } from "/opt/build/repo/frontend/pages/index.vue?macro=true";
import { default as pageZB1m7v8C5YMeta } from "~/strapi-pages/page.vue?macro=true";
import { default as blogPostlYsAxC8GClMeta } from "~/strapi-pages/blogPost.vue?macro=true";
import { default as caseHuIuXghG5PMeta } from "~/strapi-pages/case.vue?macro=true";
import { default as landingPage3xsCkfWs9IMeta } from "~/strapi-pages/landingPage.vue?macro=true";
export default [
  {
    name: articlesh9E8fquLDRMeta?.name ?? "articles",
    path: articlesh9E8fquLDRMeta?.path ?? "/articles",
    meta: articlesh9E8fquLDRMeta || {},
    alias: articlesh9E8fquLDRMeta?.alias || [],
    redirect: articlesh9E8fquLDRMeta?.redirect,
    component: () => import("/opt/build/repo/frontend/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: indexdATqU9DngJMeta?.name ?? "index",
    path: indexdATqU9DngJMeta?.path ?? "/",
    meta: indexdATqU9DngJMeta || {},
    alias: indexdATqU9DngJMeta?.alias || [],
    redirect: indexdATqU9DngJMeta?.redirect,
    component: () => import("/opt/build/repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "pharma",
    path: pageZB1m7v8C5YMeta?.path ?? "/industries/pharma/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":51,"category":"industries"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "product-catalog",
    path: pageZB1m7v8C5YMeta?.path ?? "/product-catalog/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":29,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "regulations-temperature-compliance",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":25,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "privacy",
    path: pageZB1m7v8C5YMeta?.path ?? "/privacy/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":13,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-mapping-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/our-mapping-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":19,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "incubators",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/incubators/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":48,"category":"data-logger"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "monitoring-price",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/monitoring-price/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":22,"category":"temperature-monitoring"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-calibration-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-calibration/our-calibration-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":16,"category":"temperature-calibration"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "summer-mapping-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/summer-mapping-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":79,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "pharmacies",
    path: pageZB1m7v8C5YMeta?.path ?? "/industries/pharmacies/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":50,"category":"industries"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "cases",
    path: pageZB1m7v8C5YMeta?.path ?? "/cases/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":17,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "refrigerators",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/refrigerators/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":47,"category":"data-logger"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "risk-based-mapping",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/risk-based-mapping/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":102,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-data-logger-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/our-data-logger-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":24,"category":"data-logger"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-compliance-audit-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/temperature-compliance-audit-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":39,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "guides",
    path: pageZB1m7v8C5YMeta?.path ?? "/guides/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":62,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "report-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/report-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":40,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-a-talk",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-a-talk/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":75,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "test",
    path: pageZB1m7v8C5YMeta?.path ?? "/test/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":81,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "liebherr-refrigerators",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/liebherr-refrigerators/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":44,"category":"data-logger"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "contact",
    path: pageZB1m7v8C5YMeta?.path ?? "/contact/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":12,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "data-logger",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":10,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-demo",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-demo/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":8,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "freezers",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/freezers/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":45,"category":"data-logger"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/our-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":27,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thanks",
    path: pageZB1m7v8C5YMeta?.path ?? "/thanks/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":78,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-monitoring",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":9,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "default-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/default-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":57,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "systems",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/systems/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":35,"category":"temperature-monitoring"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "setup",
    path: pageZB1m7v8C5YMeta?.path ?? "/setup/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":86,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "protocol-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/protocol-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":37,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "terms-and-conditions",
    path: pageZB1m7v8C5YMeta?.path ?? "/terms-and-conditions/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":28,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "automating-temperature-compliance-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/automating-temperature-compliance-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":41,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "challenges-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-calibration/challenges-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":42,"category":"temperature-calibration"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "hidden-costs-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/hidden-costs-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":76,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "utilize-data-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/utilize-data-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":38,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ceiv-pharma-guide",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/ceiv-pharma-guide/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":85,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ceiv",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/ceiv/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":83,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "gxp",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/gxp/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":82,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-monitoring-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/our-monitoring-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":21,"category":"temperature-monitoring"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you-post-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you-post-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":53,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mapping-price",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mapping-price/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":23,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "partners",
    path: pageZB1m7v8C5YMeta?.path ?? "/partners/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":14,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "21-cfr-part-11-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":43,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you-post-mapping-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you-post-mapping-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":91,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":72,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you-w",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you-w/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":71,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "21-cfr-part-11-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":55,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "about-us",
    path: pageZB1m7v8C5YMeta?.path ?? "/about-us/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":4,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "unify-temperature-compliance",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/unify-temperature-compliance/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":34,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-price-talk",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-price-talk/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":74,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "careers",
    path: pageZB1m7v8C5YMeta?.path ?? "/careers/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":5,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "eliminate-gaps-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/eliminate-gaps-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":77,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "webinar-sign-up",
    path: pageZB1m7v8C5YMeta?.path ?? "/webinar-sign-up/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":80,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "digital-tool-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/digital-tool-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":84,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mistakes-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mistakes-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":61,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ultimate-guide",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/ultimate-guide/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":54,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "guide-reduce-work",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/guide-reduce-work/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":56,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "report-template",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/report-template/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":59,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mistakes-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mistakes-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":36,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ultra-low-freezers",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/ultra-low-freezers/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":46,"category":"data-logger"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "catalog",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/catalog/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":87,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "webinars",
    path: pageZB1m7v8C5YMeta?.path ?? "/webinars/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":26,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "gacp-gmp-cannabis",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/gacp-gmp-cannabis/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":108,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "continuous-validation-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/continuous-validation-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":110,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "adopt-fda-gdp",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/adopt-fda-gdp/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":98,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "frequent-issues-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/frequent-issues-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":90,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-calibration",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-calibration/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":11,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-mapping",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":15,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "catalog-holmhalby",
    path: pageZB1m7v8C5YMeta?.path ?? "/catalog-holmhalby/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":103,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "WHO-guidelines-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/WHO-guidelines-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":89,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "who-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/who-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":94,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "eagle-air-freight",
    path: pageZB1m7v8C5YMeta?.path ?? "/cases/eagle-air-freight/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":100,"category":"cases"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "who-mapping-guidelines",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/who-mapping-guidelines/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":95,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "certifications-accreditations",
    path: pageZB1m7v8C5YMeta?.path ?? "/certifications-accreditations/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":104,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "automation-digitalization-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/automation-digitalization-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":111,"category":""} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "FDA-21-cfr-part-11-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/FDA-21-cfr-part-11-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":106,"category":"regulations-temperature-compliance"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mapping-mistakes-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mapping-mistakes-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":109,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "winter-mapping-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/winter-mapping-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":101,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "gxp-protocols-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/gxp-protocols-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":107,"category":"temperature-mapping"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "mapping-validation-difference",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/mapping-validation-difference/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":16,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "FDA-inspections-in-pharma",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/FDA-inspections-in-pharma/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":34,"category":"regulations-temperature-compliance"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "equipment",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/equipment/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":13,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "guidelines-pharmaceutical",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/guidelines-pharmaceutical/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":30,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "training",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/training/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":9,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "unify-compliance",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/unify-compliance/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":8,"category":"regulations-temperature-compliance"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "eupry-dtu-vet",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/eupry-dtu-vet/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":29,"category":"news"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "temperature-qualification",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/temperature-qualification/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":32,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "21-cfr-part-11",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":4,"category":"regulations-temperature-compliance"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "compliance-solutions",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/compliance-solutions/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":6,"category":"regulations-temperature-compliance"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "8-calibration-components",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-calibration/8-calibration-components/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":20,"category":"temperature-calibration"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "controling-temperature-in-your-cooling-equipement-during-summer",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-monitoring/controling-temperature-in-your-cooling-equipement-during-summer/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":24,"category":"temperature-monitoring"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "protocol",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/protocol/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":25,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "analyze-data-from-mapping-exercise",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/analyze-data-from-mapping-exercise/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":11,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "why-is-calibration-important",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-calibration/why-is-calibration-important/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":19,"category":"temperature-calibration"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "iso-17025",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/iso-17025/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":5,"category":"regulations-temperature-compliance"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "calibration-solutions",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-calibration/calibration-solutions/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":21,"category":"temperature-calibration"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "what-you-need-to-know-about-temperature-data-loggers",
    path: blogPostlYsAxC8GClMeta?.path ?? "/data-logger/what-you-need-to-know-about-temperature-data-loggers/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":22,"category":"data-logger"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "winter-mapping",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/winter-mapping/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":31,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "key-players-for-temperature-mapping",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/key-players-for-temperature-mapping/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":10,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "21-cfr-part-11-compliant-temperature-monitoring-systems",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11-compliant-temperature-monitoring-systems/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":12,"category":"regulations-temperature-compliance"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "usb-data-logger",
    path: blogPostlYsAxC8GClMeta?.path ?? "/data-logger/usb-data-logger/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":15,"category":"data-logger"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "danish-technological-institute-eupry-are-collaborating-on-digital-calibration-certificates",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/danish-technological-institute-eupry-are-collaborating-on-digital-calibration-certificates/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":28,"category":"news"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "using-a-mapping-kit",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/using-a-mapping-kit/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":14,"category":"temperature-mapping"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "why-is-the-temperature-fluctuating-in-my-cooling-equipment",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-monitoring/why-is-the-temperature-fluctuating-in-my-cooling-equipment/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":18,"category":"temperature-monitoring"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "eupry-receives-e-2-4m-investment-from-the-danish-growth-fund-and-ugly-duckling-ventures",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/eupry-receives-e-2-4m-investment-from-the-danish-growth-fund-and-ugly-duckling-ventures/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":27,"category":"news"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: caseHuIuXghG5PMeta?.name ?? "wfs",
    path: caseHuIuXghG5PMeta?.path ?? "/cases/wfs/",
    meta: { ...(caseHuIuXghG5PMeta || {}), ...{"ID":6,"category":"cases"} },
    alias: caseHuIuXghG5PMeta?.alias || [],
    redirect: caseHuIuXghG5PMeta?.redirect,
    component: () => import("~/strapi-pages/case.vue").then(m => m.default || m)
  },
  {
    name: caseHuIuXghG5PMeta?.name ?? "dsv-sweden",
    path: caseHuIuXghG5PMeta?.path ?? "/cases/dsv-sweden/",
    meta: { ...(caseHuIuXghG5PMeta || {}), ...{"ID":5,"category":"cases"} },
    alias: caseHuIuXghG5PMeta?.alias || [],
    redirect: caseHuIuXghG5PMeta?.redirect,
    component: () => import("~/strapi-pages/case.vue").then(m => m.default || m)
  },
  {
    name: caseHuIuXghG5PMeta?.name ?? "genera-research",
    path: caseHuIuXghG5PMeta?.path ?? "/cases/genera-research/",
    meta: { ...(caseHuIuXghG5PMeta || {}), ...{"ID":4,"category":"cases"} },
    alias: caseHuIuXghG5PMeta?.alias || [],
    redirect: caseHuIuXghG5PMeta?.redirect,
    component: () => import("~/strapi-pages/case.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-devices",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-devices/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":60,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "winter-temperature-mapping-standard",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/winter-temperature-mapping-standard/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":5,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "alternative-to-controlant",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/alternative-to-controlant/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":7,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-monitoring-de",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-monitoring-de/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":13,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "logistics-truck-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/logistics-truck-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":59,"category":"temperature-mapping"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "standard-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/standard-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":30,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-humidity-sensor",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-humidity-sensor/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":63,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-logistics",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-logistics/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":49,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "vaccine-temperature-data-loggers",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/vaccine-temperature-data-loggers/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":64,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-biotech",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-biotech/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":45,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "facilities-management-monitoring-system",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/facilities-management-monitoring-system/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":66,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "standard-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/standard-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":12,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-device-de",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-device-de/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":14,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "summer-temperature-mapping-simple",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/summer-temperature-mapping-simple/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":55,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-kit",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/temperature-mapping-kit/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":18,"category":"temperature-mapping"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "scada-wireless-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/scada-wireless-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":67,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-humidity-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-humidity-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":65,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "wireless-temperature-data-loggers",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/wireless-temperature-data-loggers/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":61,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-laboratories",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-laboratories/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":44,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-temperature-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-temperature-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":6,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-humidity-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-humidity-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":62,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "warehouse-temperature-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/warehouse-temperature-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":19,"category":"temperature-mapping"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "summer-temperature-mapping-standard",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/summer-temperature-mapping-standard/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":54,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "humidity-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/humidity-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":31,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "product-catalog-de",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/product-catalog-de/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":3,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-device",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-monitoring-device/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":20,"category":""} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-lp",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-lp/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":10,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "dsv-logistics-case",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/dsv-logistics-case/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":57,"category":"temperature-mapping"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "wireless-temperature-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/wireless-temperature-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":17,"category":""} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-pharma",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-pharma/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":42,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-warehouse",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-warehouse/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":32,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-temperature-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-temperature-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":11,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "calibration",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/calibration/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":2,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperatur-monitorering",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperatur-monitorering/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":22,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-logistics",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-logistics/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":43,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "iq-oq-pq",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/iq-oq-pq/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":68,"category":"temperature-mapping"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":15,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "da",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/da/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":21,"category":""} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "biotech",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/industries/biotech/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":53,"category":"industries"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-warehouse",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-warehouse/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":47,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "laboratories",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/industries/laboratories/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":52,"category":"industries"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "1BVt0oRrDTUO3Khvoeu0E1gfk989",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/1BVt0oRrDTUO3Khvoeu0E1gfk989/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":58,"category":""} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "logistics",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/industries/logistics/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":51,"category":"industries"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-biotech",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-biotech/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":50,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-pharma",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-pharma/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":48,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "winter-temperature-mapping-simple",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/winter-temperature-mapping-simple/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":9,"category":"lp"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  }
]